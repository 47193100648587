import {MenuCreateOptionInterface} from "kernel/types/module";
// @ts-ignore
import siteConfig from '@/config/site.js'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      adminTop: [
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          icon: 'fa fa-search',
          label: this.$t('page.search-records'),
          route: {
            name: 'search-records',
          },
        },
        
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_MAINTAINER']),
          label: 'Debug',
          icon: 'fa fa-bug',
          group: [
            {
              label: 'Log Viewer',
              route: { name: 'admin.log-viewer' },
              blank: true,
            },
            {
              label: 'Mail Template',
              route: {
                name: 'admin.mail-template',
              },
            },
            {
              label: 'Html Editor',
              route: {
                name: 'admin.html-editor',
              },
            },
          ],
        },
      ],
      admin: [
        // {
        //   create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
        //   label: this.$t('photo_manager'),
        //   icon: 'fa fa-images',
        //   route: {
        //     name: 'photo',
        //   },
        // },
        // {
        //   create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
        //   label: this.$t('file_manager'),
        //   icon: 'fa fa-folder-open',
        //   route: {
        //     name: 'file',
        //   },
        // },
        {
          create: (options: MenuCreateOptionInterface ) => options.hasRole(['ROLE_ADMIN']),
          label: this.$t('page.otp'),
          icon: 'fa fa-key',
          route: {
            name: 'otp',
          },
        },
      ],
    }
  }
}

export default () => (new menu()).get()
